.theme-selector-button {
    font-size: 22px;
    padding: 12px 16px 12px 16px;
}

.theme-selector-button-selected {
    color: #1F8396;
}

@media (max-width: 767px) {
    .theme-selector-button {
        padding: 4px 12px 4px 12px;
        font-size: 14px;
    }
}