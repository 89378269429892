@import url('https://fonts.googleapis.com/css?family=PT+Sans');

body {
  margin: 0;
  font-family: 'PT Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #41899F;
}


html, body, div {
  margin: 0;
  padding: 0;
}